<template>
  <div class="print">
    <s-header />
    <n-bar :userType="'teacher'" :activeItemName="'printCenter'" />
    <support />

    <div class="pageContent">
      <div class="upFile">
        <van-image
          width="3vw"
          height="3vw"
          :src="require('@/assets/images/icon12.png')"
        />
        <div @click="upFileClick">上传文件</div>
      </div>
      <div class="in main">
        <div class="title">
          <h4 style="margin: 0">数据概览</h4>
          <div @click="moreData">更多数据></div>
        </div>

        <ul class="grid">
          <li
            v-for="(item, index) in dataOverview"
            :style="{
              backgroundImage: `linear-gradient(90deg,${printDataOverViewColor[index]})`,
            }"
          >
            <p>{{ item.text }}</p>
            <p>{{ item.value }}个</p>
          </li>
        </ul>

        <div class="title">
          <h4 style="margin: 0">最近文档</h4>
          <div @click="moreFile">更多文档></div>
        </div>

        <div class="fileListBox">
          <van-list
            v-model:loading="fileLoading"
            :finished="fileLoadingFinished"
            :finished-text="fileList.length ? '没有更多了' : ''"
            @load="getFileList"
          >
            <FileList :list="fileList"></FileList>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import FileList from "../components/FileList.vue";
import { printDataOverViewColor } from "../components/gradientcolor";
import { apiPrintOverview, apiSearchFile } from "@/service/teacher1.4.js";
import { getMyDate } from "@/common/js/utils.js";

const router = useRouter();

onBeforeMount(() => {
  getOverview();
});

// 数据概览
let dataOverview = ref([]);
const getOverview = async () => {
  let res = await apiPrintOverview();
  if (res) {
    res.data.today.forEach((item) => {
      dataOverview.value.push(
        { text: "今日上传文档", value: item.count },
        { text: "今日打印文档", value: item.printCount },
        { text: "今日创建学案", value: item.caseNum }
      );
    });
    res.data.week.forEach((item) => {
      dataOverview.value.push(
        { text: "近7日上传文档", value: item.count },
        { text: "近7日打印文档", value: item.printCount },
        { text: "近7日创建学案", value: item.caseNum }
      );
    });
    res.data.month.forEach((item) => {
      dataOverview.value.push(
        { text: "本月上传文档", value: item.count },
        { text: "本月打印文档", value: item.printCount },
        { text: "本月创建学案", value: item.caseNum }
      );
    });
    res.data.all.forEach((item) => {
      dataOverview.value.push(
        { text: "总上传文档", value: item.count },
        { text: "总打印文档", value: item.printCount },
        { text: "总创建学案", value: item.caseNum }
      );
    });
    console.log(dataOverview.value);
  }
};

// 文档列表
let fileLoading = ref(false);
let fileLoadingFinished = ref(false);
let fileList = ref([]);
const getFileList = async () => {
  fileLoading.value = true;
  fileLoadingFinished.value = true;
  let res = await apiSearchFile();
  if (res) {
    res.data.forEach((item) => {
      item.case_info = JSON.parse(item.case_info);
      item.print_setting = JSON.parse(item.print_setting);
      item.time = getMyDate(item.createtime);
    });
  }
  fileList.value = res.data.splice(res.data.length - 4, res.data.length);
};

// 上传文件
const upFileClick = () => {
  router.push({ path: "/teacher/printFile" });
};

const moreData = () => {
  router.push({ path: "/teacher/morePrintData" });
};

const moreFile = () => {
  router.push({ path: "/teacher/morePrintFile" });
};
</script>

<style lang="less" scoped>
@import "@/common/style/pageContent.less";
@import "@/common/style/adapting.less";

.main {
  background-color: rgb(247, 244, 244);
  font-size: 10px;
  padding-bottom: 60px;
  .title {
    display: flex;
    justify-content: space-between;
    div:nth-child(2) {
      color: #18a4e0;
    }
  }
  .grid {
    // height: 300px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
    grid-gap: 10px;
    margin: 10px 0;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 6px;
    li {
      background-color: #18a4e0;
      height: 50px;
      border-radius: 6px;
      p {
        text-align: center;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
  .fileListBox {
    background-color: #fff;
    border-radius: 6px;
  }
}
.upFile {
  display: flex;
  align-items: center;
  color: #18a4e0;
  padding: 10px;
  justify-content: flex-end;
  position: sticky;
  top: 30px;
}
</style>
